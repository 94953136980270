import React from 'react';
import Link from 'gatsby-link';
import Layout from '../layouts';

import { TitleAndMetaTags } from '../components/TitleAndMetaTags/TitleAndMetaTags';
import { Section } from '../components/Section/Section';
import Countdown from '../components/Countdown/Countdown';

function IndexPage({ data, location }) {
	return (
		<Layout>
			<div className="main">
				<TitleAndMetaTags />
				<Section className="home-hero cover bg-center">
					<div className="w-70-l w-100 ph3 ph4-l center">
						<h1 className="title mt0 mb3">
							Giovedì Pizza
							<br />
							<span>Antenna tra la terra e Dio.</span>
						</h1>
						<Countdown />
					</div>
				</Section>
			</div>
		</Layout>
	);
}

export default IndexPage;
