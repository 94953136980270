import React, { Component } from 'react';
import Typewriter from './../Typewriter/Typewriter'
import moment from 'moment';

class Countdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			days: 0,
			hours: 0,
			min: 0,
			sec: 0
		};
	}

	getNextThursday() {
		const dayINeed = 4; // for Thursday

		let thursdayStart = moment().isoWeekday(dayINeed);
		thursdayStart.set({ h: 0, m: 0, s: 0 });

		let thursdayEnd = moment(thursdayStart).add(1, 'day');

		if (moment().isoWeekday() <= dayINeed && moment() <= thursdayEnd) {
			return moment()
				.isoWeekday(dayINeed)
				.set({ h: 0, m: 0, s: 0 });
		} else {
			return moment()
				.add(1, 'weeks')
				.isoWeekday(dayINeed)
				.set({ h: 0, m: 0, s: 0 });
		}
	}

	tick() {
		const { days, hours, min, sec } = this.state;

		// Reset Timer
		if (days === 0 && min === 0 && hours === 0 && sec === 0) {
			this.setState({ days: 6 });
		}

		// Decrement minutes
		if (sec === 0) {
			this.setState(prevState => ({ min: prevState.min - 1 }));
			this.setState({ sec: 59 });
		} else this.setState(prevState => ({ sec: prevState.sec - 1 }));

		// Decrement Hours
		if (sec === 0 && min === 0) {
			this.setState(prevState => ({ hours: prevState.hours - 1 }));
			this.setState({ min: 59 });
		}

		// Decrement Days
		if (sec === 0 && min === 0 && hours === 0) {
			this.setState(prevState => ({ days: prevState.days - 1 }));
			this.setState({ hours: 23 });
		}
	}

	componentDidMount() {
		// Set Dates
		let startDate = this.getNextThursday();
		let currentDate = moment();
		let endDate = moment(startDate).add(1, 'day');

		if (currentDate.isBetween(startDate, endDate)) {
			document.getElementById('countdown').classList.add('dn');
			document.getElementById('live').classList.remove('dn');
		} else {
			document.getElementById('countdown').classList.remove('dn');
			document.getElementById('live').classList.add('dn');
		}

		// Find the difference between dates
		let diff = (currentDate - startDate) / 1000;
		diff = Math.abs(Math.floor(diff));

		// Check number of days until target
		let days = Math.floor(diff / (24 * 60 * 60));
		let sec = diff - days * 24 * 60 * 60;

		// Check number of hours until target
		let hrs = Math.floor(sec / (60 * 60));
		sec = sec - hrs * 60 * 60;

		// Check number of minutes until target
		let min = Math.floor(sec / 60);
		sec = sec - min * 60;

		this.setState({
			days: days,
			hours: hrs,
			min: min,
			sec: sec
		});

		// Update time
		this.interval = setInterval(() => this.tick(), 1000);
	}

	render() {
		const { days, hours, min, sec } = this.state;

		return (
			<div>
				<div id="countdown" className="dn">
					<li id="days">
						<div className="number">{days}</div>
						<div className="label">Days</div>
					</li>
					<li id="hours">
						<div className="number">{hours}</div>
						<div className="label">Hours</div>
					</li>
					<li id="minutes">
						<div className="number">{min}</div>
						<div className="label">Minutes</div>
					</li>
					<li id="seconds">
						<div className="number">{sec}</div>
						<div className="label">Seconds</div>
					</li>
				</div>

				<div id="live" className="dn">
					<h2>
						<span className="title-desc">Quale pizza sceglierai?</span><br/>
						<Typewriter strings={[
							'Fichi.',
							'Rucola e Grana.',
							'Prosciutto e funghi.',
							'Prosciutto cotto.',
							'Margherita.',
							'Marinara.',
							'Kebab.',
							'Brie e speck.',
							'Ortolana.',
							'Quattro Stagioni.',
							'Salame piccante.',
							'Bufalina.',
							'Speck e scamorza.',
							'Napoletana.',
							'Crudo.',
							'Tonno e cipolle.',
							'Parmigiana.',
							'Ragù.',
							'Speck e porcini.',
							'Fritta.',
						]}/>
					</h2>

					<h3>
						Utilizza l'hashtag{' '}
						<a
							hreg="https://www.instagram.com/explore/tags/giovedipizza/"
							target="_blank">
							<b>#giovedipizza</b>
						</a>
					</h3>
				</div>
			</div>
		);
	}
}

export default Countdown;
