import React, { Component } from 'react';
import Typed from 'typed.js';

class Typewriter extends Component {
        
    componentDidMount() {

        let { strings } = this.props;

        const options = {
            strings: strings,
            typeSpeed: 50,
            backSpeed: 100,
            shuffle: true,
            smartBackspace: true,
        };

        this.typed = new Typed(this.el, options);
    }

    componentWillUnmount() {
        this.typed.destroy();
    }

    render() {
        return (
            <span
                style={{ whiteSpace: 'pre' }}
                ref={(el) => { this.el = el; }}
            />
        );
    }
}

export default Typewriter;